
import { Component, Vue } from 'vue-property-decorator'
import { UserPage } from '@/types/userAdmin'
import { KeepAlive } from '@/utils/decorators'
import { Table } from '../../types/common'

@Component({
  name: 'UserPage'
})
@KeepAlive
export default class extends Vue {
  private roleList: Array<{ role: string; roleName: string }> = []
  private searchInfo = {
    name: '',
    role: '',
    isDel: ''
  }

  private tableData: Table = {
    loading: false,
    tr: [
      {
        id: '1',
        label: '姓名',
        prop: 'name',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        id: '2',
        label: '手机号',
        prop: 'mobile',
        minWidth: '100'
      },
      {
        id: '3',
        label: '角色',
        prop: 'roleName',
        minWidth: '100'
      },
      {
        id: '4',
        label: '状态',
        prop: 'isDel',
        show: 'template',
        minWidth: '100'
      },
      {
        id: '5',
        label: '创建时间',
        prop: 'ctime',
        minWidth: '150'
      }
    ],
    data: []
  }

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getRoleList()
    this.getData()
  }

  // 获取角色列表
  getRoleList () {
    this.$axios.get(this.$apis.role.selectIparkAdminRoleByList, {}).then((res) => {
      this.roleList = res || []
    })
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get<UserPage>(this.$apis.userAdmin.userPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then((res) => {
      this.tableData.data = res.list || []
      this.total = res.total || 0
      this.tableData.loading = false
    }).catch((err) => {
      this.tableData.loading = false
      console.log(err)
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  userAdd () {
    this.$router.push({
      name: 'userAdd'
    })
  }

  // 编辑
  onUpdate (id: string) {
    this.$router.push({
      name: 'userUpdate',
      params: { id }
    })
  }

  // 重置
  onReset (id: string) {
    this.$axios.post(this.$apis.userAdmin.userResetPassword, {
      userId: id
    }).then(() => {
      this.$message({
        message: '密码重置成功',
        type: 'success'
      })
      this.getData()
    }).catch(err => {
      this.$message.error(err)
    })
  }

  // 禁用/启用
  onAble (id: string) {
    this.$axios.post(this.$apis.userAdmin.userDelete, {
      userId: id
    }).then(() => {
      this.$message({
        message: '操作成功',
        type: 'success'
      })
      this.getData()
    }).catch(err => {
      this.$message.error(err)
    })
  }

  // 删除
  onDelete (id: string) {
    this.$axios.post(this.$apis.userAdmin.userDelete, {
      userId: id
    }).then(() => {
      this.$message({
        message: '操作成功',
        type: 'success'
      })
      this.getData()
    }).catch(err => {
      this.$message.error(err)
    })
  }
}
